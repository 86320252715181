export const DEFAULT_EMPTY = '-';

export const DELAY_TRANSITION = 150;

export const DATE_FORMAT = 'DD/MM/YYYY';

export const TIME_FORMAT = 'HH:mm';

export const DATETIME_FORMAT = 'HH:mm - DD/MM/YYYY';

export const APP_NAME = 'The New Gym';

export const HEADER_HEIGHT = 54;

export const THEME_COLOR = {
  MAIN: '#009fc2',
  SUB: '#e7f5f7',
};

export const UNIT_PRICE = 'Đ';

export const CLUB_DETAIL_MAPPING = {
  1: 'https://thenewgym.vn/the-new-gym-hoang-van-thu/',
  4: 'https://thenewgym.vn/the-new-gym-dien-bien-phu/',
  5: 'https://thenewgym.vn/the-new-gym-nguyen-chi-thanh/',
  6: 'https://thenewgym.vn/the-new-gym-nguyen-thi-thap/',
  7: 'https://thenewgym.vn/the-new-gym-ung-van-khiem/',
  8: 'https://thenewgym.vn/the-new-gym-bien-hoa/',
  9: 'https://thenewgym.vn/the-new-gym-vung-tau/',
  10: 'https://thenewgym.vn/the-new-gym-hau-giang/',
  11: 'https://thenewgym.vn/the-new-gym-can-tho/',
  13: 'https://thenewgym.vn/system/da-nang/',
  23: 'https://thenewgym.vn/system/phan-dang-luu/',
  24: 'https://thenewgym.vn/system/au-co/',
  25: 'https://thenewgym.vn/system/the-new-gym-quang-trung/',
};
