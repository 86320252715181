import { LocalStorage, STORAGE_KEY } from 'utils';
import styles from './styles.module.scss';
import { CustomSwitch } from 'components';
import { useState } from 'react';
import { LANGUAGE } from 'enum';
import { useTranslation } from 'react-i18next';

const Storage = new LocalStorage();

export default function Header() {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);

  const handleChangeLanguage = (checked: boolean) => {
    if (!checked) {
      setLanguage(LANGUAGE.EN);
      Storage.setStorageItem(STORAGE_KEY.LANGUAGE, LANGUAGE.EN);
      i18n.changeLanguage(LANGUAGE.EN);
    } else {
      setLanguage(LANGUAGE.VN);
      Storage.setStorageItem(STORAGE_KEY.LANGUAGE, LANGUAGE.VN);
      i18n.changeLanguage(LANGUAGE.VN);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.logo}>
          <img src="/images/logo/logo-new-gym.png" />
        </div>
        <div className={styles.action}>
          <CustomSwitch
            defaultText="EN"
            activeText="VN"
            isChecked={language !== LANGUAGE.EN}
            onChange={handleChangeLanguage}
          />
        </div>
      </div>
    </div>
  );
}
